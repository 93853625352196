import { Authority } from '@/shared/security/authority';

const CategorySettings = () => import('@/pages/settings/sub-pages/category.settings.vue');
const PercentageSettings = () => import('@/pages/settings/sub-pages/percentage.settings.vue');
const InsuranceSettings = () => import('@/pages/settings/sub-pages/insurance.settings.vue');
const Settings = () => import('@/pages/settings/settings.vue');

const CustomerList = () => import('@/pages/customer/list/customer.vue');
const Report = () => import('@/pages/report/list/report.vue');
const TextSettings = () => import('@/pages/settings/sub-pages/text-settings.vue');
const CustomerEdit = () => import('@/pages/customer/edit/customer-edit.vue');

export default [
  {
    path: '/einstellungen',
    component: Settings,
    meta: { authorities: [Authority.USER] },
    children: [
      {
        path: 'prozentsatz',
        name: 'PercentageSettings',
        component: PercentageSettings,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'kategorie',
        name: 'CategorySettings',
        component: CategorySettings,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'sparten',
        name: 'InsuranceSettings',
        component: InsuranceSettings,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'textbausteine',
        name: 'TextSettings',
        component: TextSettings,
        meta: { authorities: [Authority.USER] },
      },
    ],
  },
  {
    path: '/kunden',
    name: 'Customer',
    component: CustomerList,
    meta: { authorities: [Authority.USER, Authority.ADMIN] },
  },
  {
    path: '/kunden/neu',
    name: 'CustomerCreate',
    component: CustomerEdit,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/kunden/:customerId/bearbeiten',
    name: 'CustomerEdit',
    component: CustomerEdit,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/berichte',
    name: 'Reports',
    component: Report,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/report-flow/step-1',
    name: 'ReportCustomerSelection',
    component: () =>
      import('@/pages/report/new/person-selection/report-customer-selection.vue'),
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/report-flow/step-2',
    name: 'ReportQuestions',
    component: () => import('@/pages/report/new/questions/report-questions.vue'),
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/report-flow/step-3',
    name: 'ReportPreview',
    component: () => import('@/pages/report/new/preview/report-preview.vue'),
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/berichte/:reportId/details',
    name: 'ReportDetails',
    component: () => import('@/pages/report/details/report-details.vue'),
    meta: { authorities: [Authority.USER] },
  },
];
