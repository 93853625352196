var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "footer",
    {
      staticClass: "footer d-print-none bg-dark text-white",
      attrs: { id: "footer" },
    },
    [
      _c("div", { staticClass: "boxed-layout pb-1" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-8" }, [
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("\n          Check den Paul Risiko-Check "),
              _c("span", { staticClass: "version" }, [
                _vm._v(_vm._s(_setup.version)),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("Berichterstellung zur Risikobewertung")]),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center mt-3" }, [
          _c("small", [
            _vm._v("\n        A\n        "),
            _c(
              "a",
              {
                staticClass: "text-white mono font-weight-normal",
                attrs: {
                  href: "https://paukowitsch.com",
                  rel: "external",
                  target: "_blank",
                },
              },
              [_vm._v("Paukowitsch Solutions Software ")]
            ),
            _vm._v("| ©\n        " + _vm._s(_setup.year) + "\n      "),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col text-right" }, [
      _c("p", { staticClass: "font-weight-bold mb-2" }, [
        _vm._v("Fehler & Support"),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("E: office@paukowitsch.com")]),
      _vm._v(" "),
      _c("p", [_vm._v("T: 0650 / 417 63 62")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }