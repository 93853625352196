var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.ribbonEnabled
    ? _c("div", { staticClass: "ribbon d-print-none" }, [
        _c("a", { attrs: { href: "" } }, [_vm._v(_vm._s(_setup.ribbonEnv))]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }