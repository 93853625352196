var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-dropdown-item", { attrs: { to: { name: "PercentageSettings" } } }, [
        _c("span", [_vm._v(" Prozentsätze")]),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: { name: "CategorySettings" } } }, [
        _c("span", [_vm._v(" Versicherungs Kategorien ")]),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: { name: "InsuranceSettings" } } }, [
        _c("span", [_vm._v(" Versicherungs Sparten")]),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: { name: "TextSettings" } } }, [
        _c("span", [_vm._v(" Textbausteine ")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }