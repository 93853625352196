import Vue from 'vue';

const TOASTER_POSITION = 'b-toaster-top-right';
const FILE_NOT_FOUND = 404;
const BAD_REQUEST = 400;

export default class AlertService {
  public showError(instance: Vue, message: string) {
    const alertMessage = message;
    (instance.$root as any).$bvToast.toast(alertMessage.toString(), {
      toaster: TOASTER_POSITION,
      title: 'Error',
      variant: 'danger',
      solid: true,
      autoHideDelay: 5000,
    });
  }
  public showCustomServerError(instance: Vue, message: string, e: any) {
    if (e.response?.data?.title) {
      const serverErrorMsg = e.response.data.title;
      if ('Bad Request' !== serverErrorMsg) {
        message = e.response.data.title;
      }
      if ('Internal Server Error' === serverErrorMsg) {
        message = 'Ein unerwarteter Fehler ist aufgetreten. Bitte überprüfen Sie Ihre eingaben.';
      }
    }
    const alertMessage = message;
    (instance.$root as any).$bvToast.toast(alertMessage.toString(), {
      toaster: TOASTER_POSITION,
      title: 'Error',
      variant: 'danger',
      solid: true,
      autoHideDelay: 5000,
    });
  }

  public showInfo(instance: Vue, message: string) {
    const alertMessage = message;
    (instance.$root as any).$bvToast.toast(alertMessage.toString(), {
      toaster: TOASTER_POSITION,
      title: 'Info',
      variant: 'info',
      solid: true,
      autoHideDelay: 5000,
    });
  }

  public showSuccess(instance: Vue, message: string) {
    const alertMessage = message;
    (instance.$root as any).$bvToast.toast(alertMessage.toString(), {
      toaster: TOASTER_POSITION,
      title: 'Erfolg',
      variant: 'success',
      solid: true,
      autoHideDelay: 5000,
    });
  }

  public showHttpError(instance: Vue, httpErrorResponse: any) {
    switch (httpErrorResponse.status) {
      case 0:
        this.showError(instance, 'Server not reachable');
        break;

      case BAD_REQUEST: {
        const arr = Object.keys(httpErrorResponse.headers);
        let errorHeader: string | null = null;
        for (const entry of arr) {
          if (entry.toLowerCase().endsWith('app-error')) {
            errorHeader = httpErrorResponse.headers[entry];
          }
        }
        if (errorHeader) {
          this.showError(instance, errorHeader);
        } else if (httpErrorResponse.data !== '' && httpErrorResponse.data.fieldErrors) {
          this.showError(instance, 'Validation error');
        } else {
          this.showError(instance, httpErrorResponse.data.message);
        }
        break;
      }

      case FILE_NOT_FOUND:
        this.showError(instance, 'Not found');
        break;

      default:
        this.showError(instance, httpErrorResponse.data.message);
    }
  }
}
