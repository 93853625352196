import http from "@/shared/config/httpService";

import { ICustomTextField } from '@/shared/model/custom-text-field.model';

const baseApiUrl = 'api/custom-text-fields';

export default class CustomTextFieldService {
  /**
   * update the given entity
   * @param entity entity to update
   */
  public async update(entity: ICustomTextField): Promise<ICustomTextField> {
    const entityDTO = JSON.parse(JSON.stringify(entity));

    entityDTO.customer = entityDTO.customer?.id;
    const res = await http.put(`${baseApiUrl}/${entityDTO.id}`, entityDTO);
    return res.data;
  }
}
