import   { AxiosPromise } from 'axios';
import http from "@/shared/config/httpService";

export default class MetricsService {
  public getMetrics(): AxiosPromise<any> {
    return http.get('management/jhimetrics');
  }

  public retrieveThreadDump(): AxiosPromise<any> {
    return http.get('management/threaddump');
  }
}
