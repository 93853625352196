import http from "@/shared/config/httpService";

import { IAnswer } from '@/shared/model/answer.model';

const baseApiUrl = 'api/answers';

export default class AnswerService {
  /**
   * Updates an existing answer entity.
   *
   * @param entity The answer entity to be updated.
   * @returns A Promise resolving to the updated answer entity.
   * @throws Throws an error if the HTTP request fails.
   */
  public async update(entity: IAnswer): Promise<IAnswer> {
    const res = await http.put(`${baseApiUrl}/${entity.id}`, entity);
    return res.data;
  }
}
