import http from "@/shared/config/httpService";

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IAppUser } from '@/shared/model/app-user.model';
import { IAppUserDTO } from '@/shared/model/app-user.dto';
const baseApiUrl = 'api/app-users';

export default class CustomAppUserService {
  public retrieveDTO(paginationQuery?: any): Promise<any> {
    return http.get(baseApiUrl + `/dto?${buildPaginationQueryOpts(paginationQuery)}`);
  }
  public deleteDTO(id: number): Promise<any> {
    return http.delete(`${baseApiUrl}/dto/${id}`);
  }
  public async createDTO(entity: IAppUserDTO): Promise<IAppUserDTO> {
    const res = await http.post(`${baseApiUrl}/dto`, entity);
    return res.data;
  }

  public find(id: number): Promise<IAppUser> {
    return new Promise<IAppUser>((resolve, reject) => {
      http.get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieve(paginationQuery?: any): Promise<any> {
    return http.get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      http.delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IAppUser): Promise<IAppUser> {
    return new Promise<IAppUser>((resolve, reject) => {
      http.post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IAppUser): Promise<IAppUser> {
    return new Promise<IAppUser>((resolve, reject) => {
      http.put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
