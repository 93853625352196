import http from "@/shared/config/httpService";
import { Store } from 'vuex';
import VueRouter from 'vue-router';

import { TOKEN_STORAGE_KEY } from '@/constants';

export default class AccountService {
  constructor(private store: Store<any>, private router: VueRouter) {}

  public get authenticated(): boolean {
    return this.store.getters.authenticated;
  }

  public get userAuthorities(): string[] {
    return this.store.getters.account?.authorities;
  }

  public async update(): Promise<void> {
    if (!this.store.getters.profilesLoaded) {
      await this.retrieveProfiles();
      this.store.commit('setProfilesLoaded');
    }
    await this.loadAccount();
  }

  public async retrieveProfiles(): Promise<boolean> {
    try {
      const res = await http.get<any>('management/info');
      if (res.data?.activeProfiles) {
        this.store.commit('setRibbonOnProfiles', res.data['display-ribbon-on-profiles']);
        this.store.commit('setActiveProfiles', res.data['activeProfiles']);
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  public async retrieveAccount(): Promise<boolean> {
    try {
      const response = await http.get<any>('api/v2/account');
      if (response.status === 200 && response.data) {
        const account = response.data;
        this.store.commit('setAuthentication', account);
        return true;
      }
    } catch (error) {}
    this.store.commit('logout');
    return false;
  }

  public async loadAccount() {
    if (this.store.getters.logon) {
      return this.store.getters.logon;
    }
    const token =
      localStorage.getItem(TOKEN_STORAGE_KEY) || sessionStorage.getItem(TOKEN_STORAGE_KEY);
    if (this.authenticated && this.userAuthorities && token) {
      return;
    }

    const promise = this.retrieveAccount();
    this.store.commit('authenticate', promise);
    promise.then(() => this.store.commit('authenticate', null));
    await promise;
  }

  public async hasAnyAuthorityAndCheckAuth(authorities: any): Promise<boolean> {
    if (typeof authorities === 'string') {
      authorities = [authorities];
    }
    return this.checkAuthorities(authorities);
  }

  private checkAuthorities(authorities: string[]): boolean {
    if (this.userAuthorities) {
      for (const authority of authorities) {
        if (this.userAuthorities.includes(authority)) {
          return true;
        }
      }
    }
    return false;
  }

  public isAdmin(){
    return this.checkAuthorities(['ROLE_ADMIN']);
  }
}
