import { Authority } from "@/shared/security/authority";

export default [
  {
    path: '/account/setup',
    name: 'SetupPage',
    component: () => import('@/pages/setup/setup.vue'),
    meta: { authorities: [Authority.USER] },
  },
];
