import { EFM_MANDANT_ID, VERDAS_MANDANT_ID } from '@/constants';

/**
 * Service class for managing logos.
 */
export default class LogoService {
  /**
   *  returns the logo url for the given mandant id
   * @param {string | string[] | number | null} mandantId
   * @return {string | null}
   */
  public findLogoByMandantId(mandantId: string | string[] | number | null): string | null {
    const logoUrl = '/content/images/';
    const { isEfm, isVerdas } = this.extractMandant(mandantId);
    if (isEfm) {
      return logoUrl + 'EFM_cropped.svg';
    }
    if (isVerdas) {
      return logoUrl + 'VERDAS.svg';
    }

    return null;
  }

  public extractMandant(mandantId: string | string[] | number | null): {
    isEfm: boolean;
    isVerdas: boolean;
  } {
    if (mandantId == null) {
      return { isEfm: false, isVerdas: false };
    }
    const mandant = Number(mandantId);
    return {
      isEfm: mandant === EFM_MANDANT_ID,
      isVerdas: mandant === VERDAS_MANDANT_ID,
    };
  }
}
