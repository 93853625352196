var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "nav-bar-layout-wrapper" },
    [
      _c(_setup.JhiNavbar),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c(_setup.JhiFooter),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }