import http from "@/shared/config/httpService";

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICategory } from '@/shared/model/category.model';

const baseApiUrl = 'api/categories';

export default class CategoryService {
  /**
   * save list of categories
   * @param entities An array of entities to save.
   */
  public async saveAll(entities: ICategory[]): Promise<ICategory[]> {
    const res = await http.post(`${baseApiUrl}/all`, entities);
    return res.data;
  }

  /**
   * get all categories for logged in user
   * @param paginationQuery
   */
  public retrieve(paginationQuery?: any): Promise<any> {
    return http.get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  /**
   * delete category with given id
   * @param id
   */
  public delete(id: number): Promise<any> {
    return http.delete(`${baseApiUrl}/${id}`);
  }
}
