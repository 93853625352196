import { TOKEN_STORAGE_KEY } from '@/constants';
import http from '@/shared/config/httpService';

const TIMEOUT = 1000000;
const onRequestSuccess = config => {
  const token =
    localStorage.getItem('cdp-gen-authenticationToken') ||
    sessionStorage.getItem('cdp-gen-authenticationToken');
  if (token) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.timeout = TIMEOUT;
  config.url = `${SERVER_API_URL}${config.url}`;
  return config;
};
/**
 * update auth token if new token is received from server
 * @param config axios config
 * @return {any} axios config
 */
const onResponseAuthCheck = config => {
  const headers = config.headers;
  const bearerToken = headers.authorization;
  if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
    console.log('updating bearer token', bearerToken);
    const jwt = bearerToken.slice(7, bearerToken.length);
    localStorage.setItem(TOKEN_STORAGE_KEY, jwt);
    sessionStorage.removeItem(TOKEN_STORAGE_KEY);
  }
  return config;
};
const setupAxiosInterceptors = (onUnauthenticated, onServerError) => {
  const onResponseError = err => {
    const status = err.status || err.response?.status;
    if (status === 403 || status === 401) {
      console.log('unauthenticated');
      return onUnauthenticated(err);
    }
    if (status >= 500) {
      return onServerError(err);
    }
    return Promise.reject(err);
  };

  if (http.interceptors) {
    http.interceptors.request.use(onRequestSuccess);
    http.interceptors.response.use(res => res, onResponseError);
    http.interceptors.response.use(onResponseAuthCheck);
  }
};

export { onRequestSuccess, setupAxiosInterceptors };
