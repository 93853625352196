import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ITextFieldSetting, TextFieldType } from '@/shared/model/text-field-setting.model';
import { IFooter } from '@/shared/model/footer.model';
import http from '@/shared/config/httpService';

const baseApiUrl = 'api/text-field-settings';

export default class TextFieldSettingService {
  private enumSettings = [
    { key: 'LETTER_HEAD', label: 'Briefkopf', order: 0, public: true },
    { key: 'EDITOR', label: 'Bearbeiter', order: 1, public: true },
    { key: 'LETTER_FOOTER', label: 'Fußzeile', order: 2, public: true, deprecated: true },
    { key: 'INTRODUCTION_GENERAL', label: 'Einführung (Cover)', order: 3, public: true },
    { key: 'FINAL_NOTE', label: 'Abschlussbemerkung (Cover)', order: 4, public: true },
    { key: 'INTRODUCTION_DETAIL_PAGE', label: 'Einführung (Detailseite)', order: 5, public: true },
    //can be removed for future updates:
    {
      key: 'CONCLUSION',
      label: 'Fazit (nicht mehr Supported)',
      order: 6,
      public: true,
      deprecated: true,
    },
    { key: 'COLOR_LEGEND_GREEN', label: 'Farblegende (grün)', order: 7, public: true },
    { key: 'COLOR_LEGEND_YELLOW_GREEN', label: 'Farblegende (gelb-grün)', order: 8, public: true },
    { key: 'COLOR_LEGEND_YELLOW', label: 'Farblegende (gelb)', order: 9, public: true },
    { key: 'COLOR_LEGEND_ORANGE', label: 'Farblegende (orange)', order: 10, public: true },
    { key: 'COLOR_LEGEND_RED_ORANGE', label: 'Farblegende (rot-orange)', order: 11, public: true },
    { key: 'COLOR_LEGEND_RED', label: 'Farblegende (rot)', order: 12, public: true },
  ];

  /**
   * load all text field settings
   * @param paginationQuery
   */
  public retrieve(paginationQuery?: any): Promise<any> {
    return http.get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  /**
   * saves all given text field settings
   * @param textFields array of text field settings
   */
  public saveAll(textFields: ITextFieldSetting[]): Promise<any> {
    return http.post(baseApiUrl + '/all', textFields);
  }

  /**
   * get the display string for the given text field type
   * @param key text field type
   */
  public getDisplayString(key: TextFieldType): string {
    const displayString = this.enumSettings.find(setting => setting.key === key)?.label;
    return displayString || '';
  }

  public sortTextSettings(unsorted: ITextFieldSetting[]): ITextFieldSetting[] {
    //sort text settings by order defined in enumSettings
    return unsorted.sort((a, b) => this.sortByOrder(a, b));
  }

  sortByOrder(a: ITextFieldSetting, b: ITextFieldSetting): number {
    const aOrder = this.enumSettings.find(setting => setting.key === a.type)?.order;
    const bOrder = this.enumSettings.find(setting => setting.key === b.type)?.order;
    if (aOrder != null && bOrder != null) {
      return aOrder - bOrder;
    } else {
      return 0;
    }
  }

  /**
   * checks if the given text field setting is deprecated (not supported anymore)
   * @param key text field type to check
   */
  checkIfDeprecated(key: TextFieldType) {
    const setting = this.enumSettings.find(item => item.key === key);
    return setting?.deprecated ?? false;
  }

  /**
   * finds footer data for customer with given id
   * @param {number} id
   * @return {Promise<IFooter>}
   */
  async findFooter(id: number): Promise<IFooter> {
    return http.get(`api/v1/footer/${id}`);
  }
}
