import { Module, MutationTree } from 'vuex';
import { ICustomer } from '@/shared/model/customer.model';
import { IAnswer } from '@/shared/model/answer.model';
import { CustomTextField, ICustomTextField } from '@/shared/model/custom-text-field.model';
import { IInsurance } from '@/shared/model/insurance.model';
import { ISummaryReport } from '@/shared/model/summary-report.model';
import { IFooter } from '@/shared/model/footer.model';

export interface ReportStateStore {
  customer: ICustomer[];
  answers: IAnswer[];
  textFields: ICustomTextField[];
  logo: any;
  ignoredAnswers: { customer: ICustomer; insurances: IInsurance[] }[];
  report: ISummaryReport | null;
  footer: IFooter | null;
}

export const defaultReportStateStore: ReportStateStore = {
  customer: [],
  answers: [],
  textFields: [],
  logo: null,
  ignoredAnswers: [],
  report: null,
  footer: null,
};

/**
 * Remove old answers for this customer and insurance category
 * @param answers answers to filter
 * @param customer customer
 * @param insuranceDescription insurance name
 */
const removeAnswer = (answers: IAnswer[], customer: ICustomer, insuranceDescription: string) => {
  return answers.filter(
    tempAnswer =>
      tempAnswer.customer.id !== customer.id ||
      tempAnswer.insuranceDescription !== insuranceDescription
  );
};
/**
 * mutations
 */
const mutations: MutationTree<ReportStateStore> = {
  setReport(state, report: ISummaryReport) {
    state.report = report;
  },
  setLogo(state, logo) {
    state.logo = logo;
  },
  setCustomer(state, customer: ICustomer[]) {
    //sort customers
    //  customer.sort((a, b) => sortReportCustomers(a, b));
    state.customer = customer;
  },
  setTextField(state, textField: ICustomTextField[]) {
    state.textFields = textField;
  },
  setAnswers(state, answers: IAnswer[]) {
    state.answers = answers;
  },
  updateTextField(state, textField: ICustomTextField) {
    //find old text field by type
    const index = state.textFields.findIndex(
      tempTextField =>
        tempTextField.type === textField.type &&
        tempTextField.efmCustomerId === textField.efmCustomerId
    );
    //replace
    state.textFields.splice(index, 1, textField);

    // update updated at timestamp of the report
    // summary report is only set if the text field was updated on the backend (has id)
    // textField.summaryReport?.updatedAt is the timestamp of the last update
    if (textField.summaryReport?.updatedAt && state.report) {
      state.report.updatedAt = textField.summaryReport.updatedAt;
    }
  },
  resetStore(state) {
    state.customer = [];
    state.answers = [];
    state.textFields = [];
    state.logo = null;
    state.ignoredAnswers = [];
  },
  addAnswer(state, answer) {
    // Step 1: Remove old answers for this customer and insurance category
    const filteredAnswers = removeAnswer(
      state.answers,
      answer.customer,
      answer.insuranceDescription
    );

    // Step 2: Add new answer
    state.answers = [...filteredAnswers, answer];
  },
  removeAnswer(state, { customer, insuranceDescription }) {
    state.answers = removeAnswer(state.answers, customer, insuranceDescription);
  },
  updateAnswer(state, answer: IAnswer) {
    //find this answer
    const index = state.answers.findIndex(
      tempAnswer =>
        tempAnswer.customer.id === answer.customer.id &&
        tempAnswer.insuranceDescription === answer.insuranceDescription
    );
    //replace
    state.answers.splice(index, 1, answer);
    //update Timestamp
    if (answer.report?.summaryReport?.updatedAt && state.report) {
      state.report.updatedAt = answer.report.summaryReport.updatedAt;
    }
  },
  setFooter(state, footer: any) {
    state.footer = footer;
  },
};

export const reportStore: Module<ReportStateStore, any> = {
  state: { ...defaultReportStateStore },
  getters: {
    report: (state: ReportStateStore): ISummaryReport => state.report,
    customer: (state: ReportStateStore): ICustomer[] => state.customer,
    answers: (state: ReportStateStore): IAnswer[] => state.answers,
    textFields: (state: ReportStateStore): CustomTextField[] => state.textFields,
    textFieldsByType: (state: ReportStateStore, type: string) =>
      state.textFields.find(textField => textField.type === type),
    logo: (state: ReportStateStore): any => state.logo,
    ignoredAnswers: (
      state: ReportStateStore
    ): { customer: ICustomer; insurances: IInsurance[] }[] => state.ignoredAnswers,
    footer: (state: ReportStateStore): IFooter | null => state.footer,
  },
  mutations,
};

/**
 * sort by name (lastname + firstname) ascending
 * @param a customer
 * @param b customer
 */
function sortByName(a: ICustomer, b: ICustomer): number {
  const lastNameA = a.lastName ?? '';
  const lastNameB = b.lastName ?? '';
  const firstNameA = a.firstName ?? '';
  const firstNameB = b.firstName ?? '';
  const fullNameA = (lastNameA + firstNameA).toLowerCase();
  const fullNameB = (lastNameB + firstNameB).toLowerCase();
  return fullNameA.localeCompare(fullNameB);
}
