import http from '@/shared/config/httpService';

import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ISummaryReport } from '@/shared/model/summary-report.model';

const summaryReportApiUrl = 'api/summary-reports';
const summaryReportAdminApiUrl = 'api/admin/summary-reports';

export default class ReportService {
  /**
   * create a new summary report
   * @param report summary report to create
   */
  public async createReport(report: ISummaryReport): Promise<ISummaryReport> {
    const res = await http.post(summaryReportApiUrl, report);
    return res.data;
  }

  /**
   * retrieve all summary reports
   * @param paginationQuery pagination query
   */
  public async retrieveAllSummaryReportsPreview(paginationQuery?: any): Promise<any> {
    return http.get(
      summaryReportApiUrl + `/list-preview?${buildPaginationQueryOpts(paginationQuery)}`
    );
  }

  /**
   * delete summary report with given id
   * @param id id of summary report to delete
   */
  public async deleteSummaryReport(id: number): Promise<any> {
    return http.delete(`${summaryReportAdminApiUrl}/${id}`);
  }

  /**
   * retrieve summary report with given id
   * @param id id of summary report to retrieve
   */
  public async find(id: number): Promise<ISummaryReport> {
    return (await http.get(`${summaryReportApiUrl}/${id}`)).data;
  }

  /**
   * downloads the pdf file from cca
   * @param {number} id - id of the summary report
   * @return {Promise<AxiosResponse<any>>} - response with the pdf file
   */
  public async downloadPDFFromCCA(id: number) {
    return await http.get(`${summaryReportApiUrl}/${id}/pdf`, {
      responseType: 'blob',
    });
  }

  /**
   * Store the summary report in CCA
   * @param {number} id - id of the summary report
   * @return {Promise<void>}
   */
  async storeInCCA(id: number): Promise<void> {
    return await http.post(`${summaryReportApiUrl}/${id}/pdf`);
  }
}
