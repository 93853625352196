import { render, staticRenderFns } from "./ribbon.vue?vue&type=template&id=f53f3cfe&scoped=true&"
import script from "./ribbon.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ribbon.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ribbon.vue?vue&type=style&index=0&id=f53f3cfe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f53f3cfe",
  null
  
)

export default component.exports