import Vue from 'vue';
import Component from 'vue-class-component';
import Router from 'vue-router';
import admin from '@/router/admin';
import pages from '@/router/pages';
import NavBarLayout from '@/layouts/navBarLayout.vue';
import { Authority } from '@/shared/security/authority';
import account from "@/router/account";

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  // for vue-router 2.2+
  'beforeRouteUpdate',
]);

const Login = () => import('@/pages/login/login.vue');
const ErrorPage = () => import('@/core/error/error.vue');
const Dashboard = () => import('@/pages/dashboard/dashboard.vue');

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',

  routes: [
    {
      path: '/',
      component: NavBarLayout,
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: Dashboard,
          meta: { authorities: [Authority.USER] }
        },
        ...account,
        ...admin,
        ...pages
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: ErrorPage,
      meta: { error403: true }
    },
    {
      path: '/not-found',
      name: 'NotFound',
      component: ErrorPage,
      meta: { error404: true }
    },
    {
      path: '/impressum',
      name: 'Impressum',
      component: () => import('@/pages/impressum/impressum.vue')
    }


  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
    return { x: 0, y: 0 };
  }
});

export default router;
