// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';

import UserManagementService from './admin/user-management/user-management.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';

import '../content/scss/global.scss';
import '../content/scss/custom.scss';
import '../content/scss/vendor.scss';
import '../content/scss/print.scss';
import PercentageService from '@/entities/percentage/percentage.service';
import CustomAppUserService from '@/pages/app-user-management/custom-app-user.service';
import AppUserService from '@/entities/app-user/app-user.service';
import CategoryService from '@/entities/category/category.service';
import CustomerService from '@/entities/customer/customer.service';
import ReportService from '@/entities/report/report.service';
import InsuranceService from './entities/insurance/insurance.service';
import LogoService from './entities/logo/logo.service';
import TextFieldSettingService from './entities/text-field-setting/text-field-settings.service';
import VueApexCharts from 'vue-apexcharts';
import CustomTextFieldService from '@/entities/custom-text-field/custom-text-field.service';
import AnswerService from '@/entities/answer/answer.service';
/* tslint:disable */

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);

bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('infinite-loading', InfiniteLoading);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);
const store = config.initVueXStore(Vue);

const loginService = new LoginService();
const accountService = new AccountService(store, router);
router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next({ name: 'NotFound' });
    return;
  }

  if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    if (!store.getters.authenticated) {
      await accountService.update();
    }
    const value = await accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities);
    if (!value) {
      //if requested url is / redirect to login
      // if (to.fullPath === "/") {
      next({ name: 'Login', query: { redirect: to.fullPath } });
      return;
      //}
      /*

            if (from.path !== "/forbidden") {
              next({ path: "/forbidden" });
              return;
            }
      */
    }
  }
  //if login page check if already logged in
  if (to.name === 'Login' && store.getters.authenticated) {
    next({ path: '/' });
    return;
  }
  // no authorities, so just proceed
  next();
});

/* tslint:disable */
const vue = new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,
    userManagementService: () => new UserManagementService(),
    healthService: () => new HealthService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    answerService: () => new AnswerService(),
    alertService: () => new AlertService(),
    percentageService: () => new PercentageService(),
    categoryService: () => new CategoryService(),
    insuranceService: () => new InsuranceService(),
    appUserService: () => new AppUserService(),
    customAppUserService: () => new CustomAppUserService(),
    customerService: () => new CustomerService(),
    reportService: () => new ReportService(),
    logoService: () => new LogoService(),
    textFieldSettingService: () => new TextFieldSettingService(),
    customTextFieldService: () => new CustomTextFieldService(),
  },
  store,
});

setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Unauthorized
      // Store logged out state.
      store.commit('logout');
      if (!url.endsWith('/account') && !url.endsWith('/authenticate')) {
        //redirect to login page
        router.push({ name: 'Login' }).then();

        return Promise.resolve(error);
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  },
  error => {
    return Promise.reject(error);
  }
);
