import { Authority } from '@/shared/security/authority';

const GenUserManagementComponent = () => import('@/admin/user-management/user-management.vue');
const GenUserManagementEditComponent = () =>
  import('@/admin/user-management/user-management-edit.vue');
const GenHealthComponent = () => import('@/admin/health/health.vue');
const GenLogsComponent = () => import('@/admin/logs/logs.vue');
const GenMetricsComponent = () => import('@/admin/metrics/metrics.vue');

export default [
  {
    path: '/admin/user-management',
    name: 'GenUser',
    component: GenUserManagementComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/new',
    name: 'GenUserCreate',
    component: GenUserManagementEditComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/:userId/edit',
    name: 'GenUserEdit',
    component: GenUserManagementEditComponent,
    meta: { authorities: [Authority.ADMIN] },
  },

  {
    path: '/admin/health',
    name: 'GenHealthComponent',
    component: GenHealthComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/logs',
    name: 'GenLogsComponent',
    component: GenLogsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/metrics',
    name: 'GenMetricsComponent',
    component: GenMetricsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/app-users',
    name: 'AppUsers',
    component: () => import('@/pages/app-user-management/app-user.vue'),
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/app-users/new',
    name: 'AppUserCreate',
    component: () => import('@/pages/app-user-management/app-user-update.vue'),
    meta: { authorities: [Authority.ADMIN] },
  },
];
