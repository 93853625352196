
import Vue from 'vue';
import Component from 'vue-class-component';
import http from "@/shared/config/httpService";

@Component
export default class LogsService extends Vue {
  public changeLevel(name: string, configuredLevel: string) {
    return http.post(`management/loggers/${name}`, { configuredLevel });
  }

  public findAll() {
    return http.get('management/loggers');
  }
}
