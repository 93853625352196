import http from "@/shared/config/httpService";

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IInsurance } from '@/shared/model/insurance.model';

const baseApiUrl = 'api/insurances';

export default class InsuranceService {
  public async saveAll(insurances: IInsurance[]): Promise<any> {
    const res = await http.post(`${baseApiUrl}/all`, insurances);
    return res.data;
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      http.get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      http.delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
